import ProductReviewRating from 'Component/ProductReviewRating';
import ShowMoreLessText from 'Component/ShowMoreLessText';
import { ProductReviewItem as SourceProductReviewItem } from 'SourceComponent/ProductReviewItem/ProductReviewItem.component';

import './ProductReviewItem.override.style';

/** @namespace Pwa/Component/ProductReviewItem/Component/ProductReviewItem */
export class ProductReviewItem extends SourceProductReviewItem {
    __construct(props) {
        super.__construct(props);
        this.renderReviewListItemRating = this.renderReviewListItemRating.bind(this);
    }

    renderAuthor(reviewItem) {
        const { nickname } = reviewItem;

        return (
            <p block="ProductReviewItem" elem="ReviewAuthor">
                <span itemProp="author">{nickname}</span>
            </p>
        );
    }

    renderReviewListItemRating(ratingVoteItem, i) {
        const {
            reviewItem: { rating_votes },
        } = this.props;

        const isMultipleRating = rating_votes.length > 1;

        const { rating_code, percent } = ratingVoteItem;

        return (
            <div
                key={i}
                block="ProductReviewItem"
                elem="RatingSummaryItem"
                itemType="http://schema.org/Rating"
                itemScope
                itemProp="reviewRating"
            >
                {isMultipleRating && (
                    <p itemProp="name" block="ProductReviewItem" elem="RatingItem">
                        {rating_code}
                    </p>
                )}
                <meta itemProp="ratingValue" content={percent} />
                <meta itemProp="worstRating" content={0} />
                <meta itemProp="bestRating" content={100} />
                <ProductReviewRating summary={percent} code={rating_code} />
            </div>
        );
    }

    render() {
        const {
            reviewItem,
            reviewItem: { detail, rating_votes },
        } = this.props;

        return (
            <li block="ProductReviewItem" itemType="http://schema.org/Review" itemProp="review" itemScope>
                <div block="ProductReviewItem" elem="RatingSummary">
                    {rating_votes.map(this.renderReviewListItemRating)}
                </div>
                <div block="ProductReviewItem" elem="Content">
                    <p block="ProductReviewItem" elem="ReviewDetails" itemProp="reviewBody">
                        <ShowMoreLessText lines={6}>{detail}</ShowMoreLessText>
                    </p>
                    {this.renderAuthor(reviewItem)}
                </div>
            </li>
        );
    }
}

export default ProductReviewItem;
