import PropTypes from 'prop-types';

import ProductReviewItem from 'Component/ProductReviewItem';
import { ProductReviewList as SourceProductReviewList } from 'SourceComponent/ProductReviewList/ProductReviewList.component';
import { ProductType } from 'Type/ProductList.type';
import { ReviewItemType } from 'Type/Rating.type';

import './ProductReviewList.override.style';

/** @namespace Pwa/Component/ProductReviewList/Component/ProductReviewList */
export class ProductReviewList extends SourceProductReviewList {
    static propTypes = {
        product: ProductType.isRequired,
        productReviews: PropTypes.arrayOf(ReviewItemType),
    };

    renderReviews() {
        const { productReviews } = this.props;

        return productReviews.map((reviewItem, i) => (
            <ProductReviewItem
                reviewItem={reviewItem}
                // eslint-disable-next-line react/no-array-index-key
                key={i}
            />
        ));
    }

    render() {
        return <ul block="ProductReviewList">{this.renderReviews()}</ul>;
    }
}

export default ProductReviewList;
