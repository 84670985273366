import { PureComponent } from 'react';

import ShowMoreLessText from './ShowMoreLessText.component';

/** @namespace Pwa/Component/ShowMoreLessText/Container/ShowMoreLessTextContainer */
export class ShowMoreLessTextContainer extends PureComponent {
    state = {
        isExpanded: false,
    };

    containerFunctions = {
        toggleExpanded: this.toggleExpanded.bind(this),
    };

    containerProps() {
        const { isExpanded } = this.state;

        return { isExpanded };
    }

    toggleExpanded() {
        this.setState((prevState) => ({
            isExpanded: !prevState.isExpanded,
        }));
    }

    render() {
        return <ShowMoreLessText {...this.props} {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default ShowMoreLessTextContainer;
