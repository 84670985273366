import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import ProductReviewList from 'Component/ProductReviewList';
import { ProductReviews as SourceProductReviews } from 'SourceComponent/ProductReviews/ProductReviews.component';

import { DEFAULT_VISIBLE_OPINIONS_AMOUNT, ONE_HUNDRED_PERCENT, STARS_COUNT } from './ProductReviews.config';

import './ProductReviews.override.style';

/** @namespace Pwa/Component/ProductReviews/Component/ProductReviews */
export class ProductReviews extends SourceProductReviews {
    renderTitle() {
        return <h2>{__('Reviews')}</h2>;
    }

    renderRatingData() {
        const {
            product: { rating_summary, review_count },
        } = this.props;

        const percent = parseFloat((STARS_COUNT * (rating_summary || 0)) / ONE_HUNDRED_PERCENT).toFixed(2);

        if (!review_count) {
            return this.renderNoRating();
        }

        return (
            <div block="ProductReviews" elem="SummaryRatings">
                <meta itemProp="ratingValue" content={percent} />
                <meta itemProp="ratingCount" content={review_count || 0} />
                <p block="ProductReviews" elem="SummaryDetails">
                    <Html
                        content={`${__(
                            'Overall rating based on %s reviews',
                            review_count || 0
                        )} <span>${percent}</span>`}
                    />
                </p>
            </div>
        );
    }

    renderLoadMore() {
        const {
            loadMoreReviews,
            productReviews,
            product: { review_count = 0 },
        } = this.props;

        if (
            (productReviews && productReviews.length >= review_count) ||
            review_count < DEFAULT_VISIBLE_OPINIONS_AMOUNT
        ) {
            return null;
        }

        return (
            <div block="ProductReviews" elem="LoadMore">
                <button
                    block="ProductReviews"
                    elem="Button"
                    mix={{ block: 'Button', mods: { isHollow: true } }}
                    onClick={loadMoreReviews}
                >
                    {__('Load More')}
                </button>
            </div>
        );
    }

    renderNoRating() {
        const {
            device: { isMobile },
        } = this.props;

        const text = isMobile
            ? __('There are no reviews yet! Click button below to submit one!')
            : __('There are no reviews yet! Click button on the right to submit one!');

        return (
            <>
                <p>{text}</p>
                <meta itemProp="ratingValue" content={0} />
            </>
        );
    }

    renderSummary() {
        return (
            <div
                block="ProductReviews"
                elem="Summary"
                itemType="http://schema.org/AggregateRating"
                itemProp="aggregateRating"
                itemScope
            >
                {this.renderTitle()}
                {this.renderRatingData()}
                {this.renderButton()}
            </div>
        );
    }

    renderList() {
        const { productReviews, defaultReviews } = this.props;

        const reviews = productReviews.length > 0 ? productReviews : defaultReviews;

        if (!reviews) {
            return null;
        }

        return <ProductReviewList productReviews={reviews} />;
    }

    render() {
        return (
            <ContentWrapper
                label="Product reviews"
                mix={{ block: 'ProductReviews' }}
                wrapperMix={{ block: 'ProductReviews', elem: 'Wrapper' }}
            >
                <div mix={{ block: 'ProductReviews', elem: 'Content' }}>
                    {this.renderSummary()}
                    {this.renderList()}
                    {this.renderLoadMore()}
                </div>
            </ContentWrapper>
        );
    }
}

export default ProductReviews;
