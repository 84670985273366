import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ReviewsSliderQuery from 'Query/ReviesSection.query';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductReviewsContainer as SourceProductReviewsContainer,
} from 'SourceComponent/ProductReviews/ProductReviews.container';
import { getIndexedReviews } from 'Util/Product';
import { fetchQuery } from 'Util/Request';

import ProductReviews from './ProductReviews.component';

/** @namespace Pwa/Component/ProductReviews/Container/ProductReviewsContainer */
export class ProductReviewsContainer extends SourceProductReviewsContainer {
    containerFunctions = {
        loadMoreReviews: this.loadMoreReviews.bind(this),
    };

    state = {
        productReviews: [],
        reviewsOnPage: 6,
        reviewsPage: 1,
    };

    async loadMoreReviews() {
        const {
            product: { id },
        } = this.props;

        const { reviewsOnPage, reviewsPage } = this.state;

        const query = ReviewsSliderQuery._getNextReviews(id, reviewsOnPage, reviewsPage);

        fetchQuery(query).then(
            /** @namespace Pwa/Component/ProductReviews/Container/fetchQuery/then */
            ({ products }) => {
                this.setState({
                    productReviews: this.state.productReviews.concat(
                        getIndexedReviews((Object.items = products.items[0].reviews))
                    ),
                    reviewsPage: this.state.reviewsPage + 1,
                });
            }
        );
    }

    containerProps() {
        const {
            extended,
            product: { reviews: defaultReviews },
        } = this.props;
        const { productReviews } = this.state;

        return {
            ...super.containerProps(),
            extended,
            productReviews,
            defaultReviews,
        };
    }

    render() {
        return <ProductReviews {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductReviewsContainer));
